<template>
  <!-- 产品介绍主页（弃用隐藏）,附加图片-->
  <!--入口 ../product-description/img/img1.png -->
  <!--页面 ../product-description/img/img2.png -->
  <div class="product-view">
    <div class="tree-box">
      <el-tree
        accordion
        node-key="label"
        ref="tree"
        :data="data"
        :props="defaultProps"
        highlight-current
        @node-click="handleNodeClick"
        :default-expanded-keys="faterPath"
      >
      </el-tree>
    </div>
    <div class="contain">
      <img
        @click="openWindow"
        src="../../../assets/images/page-background.png"
        style="width: 100%; height: 100px"
      />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      data: [
        {
          label: "管理系统",
          children: [
            {
              label: "信息资产管理软件",
              path: "product-son-page26",
            },
            {
              label: "6S管理软件",
              path: "product-son-page27",
            },
            {
              label: "体系审核软件",
              path: "product-son-page28",
            },
            {
              label: "APQP项目管理软件",
              path: "product-son-page30",
            },
            {
              label: "质量零缺陷分析系统（FMEA管理软件）",
              path: "product-son-page24",
            },
            {
              label: "设备管理软件",
              path: "product-son-page29",
            },
            {
              label: "流程优化软件",
              path: "product-son-page31",
            },
            {
              label: "绩效管理软件",
              path: "product-son-page32",
            },

            // {
            //   label: "信息扁平化",
            //   path: "product-son-page4",
            // },
          ],
        },
        {
          label: "体系搭建",
          children: [
            {
              label: "构建体系树",
              path: "product-son-page33",
            },
            {
              label: "管理体系文件分类",
              path: "product-son-page46",
            },
            {
              label: "流程接口（包括部门接口）",
              path: "product-son-page11",
            },
            {
              label: "构建体系信息流",
              path: "product-son-page3",
            },
            {
              label: "构建合理的目标指标",
              path: "product-son-page42",
            },
            {
              label: "流程要素数字化",
              path: "product-son-page1",
            },
            {
              label: "体系文件分类数字化",
              path: "product-son-page2",
            },
            {
              label: "表单数字化",
              path: "product-son-page5",
            },
            {
              label: "构建体系数据链",
              path: "product-son-page6",
            },
            {
              label: "体系文件数字化的网格化",
              path: "product-son-page7",
            },
            {
              label: "智能制造体系构建",
              path: "product-son-page12",
            },

            // {
            //   label: "体系数字化分工",
            //   path: "product-son-page8",
            // },
            // {
            //   label: "体系树",
            //   path: "product-son-page9",
            // },
            // {
            //   label: "体系扁平化",
            //   path: "product-son-page10",
            // },
          ],
        },
        {
          label: "体系维护",
          children: [
            {
              label: "每年体系树的维护和更新",
              path: "product-son-page34",
            },
            {
              label: "每年保持各个岗位说明书与操作文件的一致性",
              path: "product-son-page35",
            },
            {
              label: "协助程序文件（二层文件）的更改",
              path: "product-son-page37",
            },
            {
              label: "每年管理手册维护和更新",
              path: "product-son-page38",
            },
            {
              label: "每年工作标准的维护和更新",
              path: "product-son-page40",
            },
            {
              label: "每年目标指标的维护和更新",
              path: "product-son-page43",
            },

            // {
            //   label: "ISO27001信息安全管理体系审核",
            //   path: "product-son-page19",
            // },

            // {
            //   label: "ISO50001能源管理体系审核",
            //   path: "product-son-page21",
            // },
          ],
        },
        {
          label: "体系落地",
          children: [
            {
              label: "构建个人工作看板",
              path: "product-son-page36",
            },

            {
              label: "构建工作标准",
              path: "product-son-page39",
            },
            {
              label: "构建体系落地检查题库",
              path: "product-son-page41",
            },
            {
              label: "ISO9001质量管理体系审核",
              path: "product-son-page17",
            },
            {
              label: "IATF16949汽车零部件质量管理体系审核",
              path: "product-son-page16",
            },
            {
              label: "ISO14001环境管理体系审核",
              path: "product-son-page18",
            },
            {
              label: "ISO45001职业健康安全管理体系审核",
              path: "product-son-page20",
            },
            {
              label: "SA8000社会责任管理体系审核",
              path: "product-son-page23",
            },
            {
              label: "IATF16949制造过程审核",
              path: "product-son-page25",
            },
            {
              label: "IATF16949产品审核",
              path: "product-son-page22",
            },
            {
              label: "其它管理体系的审核",
              path: "product-son-page45",
            },
            {
              label: "潜在供应商审核",
              path: "product-son-page44",
            },
            {
              label: "关键供应商审核",
              path: "product-son-page13",
            },
            {
              label: "企业现场5S检查",
              path: "product-son-page14",
            },
            {
              label: "企业现场安全检查",
              path: "product-son-page15",
            },
          ],
        },
        {
          label: "培训课程",
          children: [],
        },
      ],
      faterPath: [],
    };
  },
  mounted() {
    if (this.$route.query.openList) {
      this.faterPath.push(this.$route.query.openList);
      this.$refs.tree.setCurrentKey(
        this.data[this.$route.query.fatherIndex].children[
          this.$route.query.childIndex
        ].label
      );
    } else {
      this.faterPath.push("管理软件");
      this.$refs.tree.setCurrentKey("流程要素数字化");
    }
  },
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
    handleNodeClick(data) {
      this.$router.push(data.path);
    },
  },
};
</script>

<style lang="less" scoped>
.product-view {
  display: flex;
  width: 100%;
  position: absolute;
  top: 52px;
  bottom: 0;
  .tree-box {
    width: 260px;
    background: #f6f8fa;
    border-top: 3px solid #e9e9e99d;
    border-right: 3px solid #e9e9e99d;
    overflow: scroll;
    padding-bottom: 50px;
    .el-tree {
      background: #f6f8fa !important;
      /deep/.el-tree-node__content {
        padding-left: 10px !important;
      }
      /deep/.el-tree-node__children {
        .el-tree-node__label {
          font-size: 13px !important;
          color: #6b6a6a;
          width: 260px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      padding-top: 10px;
    }
    /deep/.el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background-color: #a0d1f4 !important;
      color: white !important;
    }
    /deep/.el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content
      .el-tree-node__label {
      color: white !important;
    }
  }

  .contain {
    flex: 1;
    background: white;
    overflow: scroll;
    padding-bottom: 40px;
  }
}
</style>